import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Formatter from 'common/Formatter';

import StatusIcon from '@/components/atoms/StatusIcon';
import AutoCompleteCompanies from '@/components/molecules/AutoCompleteCompanies';
import { ShareDataModel, DocumentTypeE, ProcessMethodE } from './process.d';
import { FilterSelect } from '@/common';
import API from '@/services/API';
import { ProcessFormModel, ProcessorSelectItem } from './model';
import { ProcessMethodList } from './config';
import { DocumentProcessTypes, DocumentProcessActionTypes } from '@/types';

interface ProcessFormProps {
  mpData: {
    processors: any[];
    mappings: any[];
  };
  rowData: any;
  shareData: ShareDataModel;
  processForm: ProcessFormModel;
  handleProcessFormChange: (key: keyof ProcessFormModel, value: any) => void;
  addActionCount: (type?: DocumentProcessTypes) => void;
}

const ProcessForm = ({
  mpData,
  shareData,
  processForm,
  rowData,
  handleProcessFormChange,
  addActionCount,
}: ProcessFormProps) => {
  const [processorList, setProcessorList] = useState<ProcessorSelectItem[]>([]);
  const [processorItemValue, setProcessorItemValue] =
    useState<ProcessorSelectItem>({
      value: '',
      label: '',
      company_name: '',
      updated_at: '',
    });

  const { data: prompts } = API.getBasicQuery('prompts');

  useEffect(() => {
    if (mpData.processors) {
      const list = mpData.processors.map((processor) => ({
        value: processor.str_id,
        label: processor.name,
        company_name: processor.companies?.company_name,
        updated_at: processor.updated_at,
      }));
      setProcessorList(list);
    }
  }, [mpData.processors]);

  useEffect(() => {
    if (rowData) {
      if (prompts) {
        const rowDataCompany = rowData.company_str_id;
        const promptCompany = (prompts as any[]).find(
          (item) => item.company_str_id === rowDataCompany
        );
        if (promptCompany) {
          handleProcessFormChange('prompt', promptCompany.company_str_id);
          handleProcessFormChange('promptText', promptCompany.prompt);
        }
      }
    }
  }, [rowData, prompts]);

  useEffect(() => {
    if (processForm.processor) {
      const processor = processorList?.find(
        (processor) => processor.value === processForm.processor
      );
      if (processor) {
        setProcessorItemValue(processor);
      }
    }
  }, [processForm.processor, processorList]);

  return (
    <Box
      sx={{
        gap: 2,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 1,
      }}
    >
      <FormControl fullWidth>
        <Tooltip title={processForm.fileName} arrow>
          <TextField
            label="File"
            variant="outlined"
            value={processForm.fileName}
            disabled
          />
        </Tooltip>
      </FormControl>

      <FormControl fullWidth>
        <TextField
          label="File type"
          variant="outlined"
          value={processForm.fileType}
          disabled
        />
      </FormControl>

      {shareData.sheets.length > 1 && (
        <FormControl fullWidth>
          <InputLabel> {shareData.isExcel ? 'Sheet' : 'Table'}</InputLabel>
          <Select
            id="select-sheet"
            value={processForm.selectedSheet}
            label={shareData.isExcel ? 'Sheet' : 'Table'}
            onChange={(e) => {
              addActionCount(DocumentProcessActionTypes.SELECT_SPREADSHEET);
              handleProcessFormChange('selectedSheet', e.target.value);
            }}
          >
            <MenuItem value="" />
            {shareData.sheets.map((sheetName) => (
              <MenuItem value={sheetName} key={sheetName}>
                {sheetName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth>
        <InputLabel>Method</InputLabel>
        <Select
          id="select-method"
          value={processForm.method}
          label="Method"
          onChange={(e) => {
            addActionCount(DocumentProcessActionTypes.SELECT_METHOD);
            handleProcessFormChange('method', e.target.value);
          }}
        >
          {ProcessMethodList.map((method) => (
            <MenuItem value={method.value} key={method.value}>
              {method.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {processForm.method === ProcessMethodE.Processor ? (
        <>
          <FormControl fullWidth>
            <FilterSelect
              id="processor"
              label="Processor"
              sx={{ width: '100%' }}
              options={processorList}
              value={processorItemValue}
              onChange={(data) => {
                addActionCount(DocumentProcessActionTypes.SELECT_PROCESSOR);
                handleProcessFormChange('processor', data?.value);
              }}
              getOptionLabel={(option) => option.label}
              renderOptionItem={(option) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography component="span">{option.label}</Typography>
                  <Typography
                    variant="caption"
                    component="span"
                    sx={{ color: '#555' }}
                  >
                    {`(${option.company_name} - ${new Date(
                      option.updated_at
                    ).toLocaleString()})`}
                  </Typography>
                </Box>
              )}
            />
          </FormControl>
        </>
      ) : processForm.method === ProcessMethodE.Gemini ? (
        <>
          <FormControl fullWidth>
            <InputLabel>Prompt</InputLabel>
            <Select
              id="select-prompt"
              value={processForm.prompt}
              label="Prompt"
              onChange={(e) => {
                addActionCount(DocumentProcessActionTypes.SELECT_GEMINI_PROMPT);
                handleProcessFormChange('prompt', e.target.value);
              }}
            >
              {(prompts || []).map((c) => (
                <MenuItem value={c.str_id} key={c.str_id}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flex: 1 }}>{c.name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : null}

      <FormControl fullWidth>
        <InputLabel>Mapping</InputLabel>
        <Select
          id="mapping"
          value={String(processForm.mapping)}
          label="Mapping"
          onChange={(e) => {
            addActionCount(DocumentProcessActionTypes.SELECT_MAPPING);
            handleProcessFormChange('mapping', e.target.value);
          }}
        >
          <MenuItem value={0}>(New)</MenuItem>
          {mpData.mappings?.map((mapping) => (
            <MenuItem value={mapping.str_id} key={mapping.str_id}>
              <Typography component="span">{mapping.name || ''}</Typography>
              <Typography
                variant="caption"
                component="span"
                sx={{ color: '#555', ml: 1 }}
              >
                {`(${
                  rowData.companies?.company_name &&
                  `${rowData.companies?.company_name} - `
                }${Formatter.date(mapping.created_at, true, 'MM/DD/YYYY hh:mmA')})`}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!processForm.mapping &&
        shareData.fileType === DocumentTypeE.Statement && (
          <FormControl fullWidth>
            <AutoCompleteCompanies
              value={processForm.newMappingCarrier}
              onChange={(event, newEvent) => {
                addActionCount(DocumentProcessActionTypes.SELECT_COMPANY);
                handleProcessFormChange('newMappingCarrier', newEvent);
              }}
            />
          </FormControl>
        )}

      {!processForm.mapping && (
        <FormControl fullWidth>
          <TextField
            label="Mapping name"
            variant="outlined"
            value={processForm.newMappingName}
            onBlur={() => {
              addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
            }}
            onChange={(e) => {
              handleProcessFormChange('newMappingName', e.target.value);
            }}
            sx={{ ml: 1 }}
          />
        </FormControl>
      )}

      {shareData.errors.mapping ? (
        <StatusIcon
          icon="info"
          color="#F4B400"
          message={shareData.errors.mapping}
        />
      ) : (
        <StatusIcon icon="complete" color="#0F9D58" message="" />
      )}
    </Box>
  );
};

export default ProcessForm;

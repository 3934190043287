import { useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash-es';
import currency from 'currency.js';
import { AgGridReact } from 'ag-grid-react';
import { Clear, FileDownload, InfoOutlined } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import {
  CellValueChangedEvent,
  ColDef,
  FilterChangedEvent,
} from 'ag-grid-community';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { tool } from '@/common/tools';
import { exportCsv } from '@/services/helpers';
import AddFieldsDialog from './addFieldDialog';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';
import {
  IFieldsProps,
  ProcessDataModel,
  ProcessMethodE,
  RangeDataModel,
  RowMappingModel,
  ShareDataModel,
} from './process.d';
import RemoveFieldsDialog from './removeFieldDialog';
import VertexResult from './VertexResult';
import { ProcessFormModel } from './model';
import { DocumentProcessActionTypes, DocumentProcessTypes } from '@/types';

interface ICommissionPreviewProps {
  rowMapping: RowMappingModel;
  setRowMapping: (val: any) => void;
  selectedDataRange: RangeDataModel;
  processedData: ProcessDataModel;
  setProcessedData: (data: any) => void;
  shareData: ShareDataModel;
  processForm: ProcessFormModel;
  missingRequiredFields: string[];
  setProcessFormatData: (data: any) => void;
  handleProcessFormChange: (k, v) => void;
  setErrors: (data: any) => void;
  addActionCount: (type?: DocumentProcessTypes) => void;
}

dayjs.extend(utc);

const CommissionPreview = ({
  rowMapping,
  setRowMapping,
  selectedDataRange,
  processedData,
  setProcessedData,
  shareData,
  processForm,
  setProcessFormatData,
  handleProcessFormChange,
  setErrors,
  missingRequiredFields,
  addActionCount,
}: ICommissionPreviewProps) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [customData, setCustomData] = useState<string[][]>([]);
  const [processedRowList, setProcessedRowList] = useState<any[]>([]);

  const [rowDataDef, setRowDataDef] = useState<RowMappingModel[]>([]);

  const [open, setOpen] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [canAddFields, setCanAddFields] = useState<IFieldsProps[]>([]);
  const [canRemoveFields, setCanRemoveFields] = useState<IFieldsProps[]>([]);

  const [showVertexRes, setShowVertexRes] = useState(false);
  const [invalidVertexRes, setInvalidVertexRes] = useState('');
  const [parsedVertexJson, setParsedVertexJson] = useState<any[]>([]);

  const onConfirmVertexRes = (jsonStr: string) => {
    if (!jsonStr) return;
    try {
      const res = JSON.parse(jsonStr);
      setParsedVertexJson(res);
      addActionCount(DocumentProcessActionTypes.FIX_EXTRACTION);
      // Update the extractions data
    } catch (err) {
      console.log(err);
    } finally {
      setShowVertexRes(false);
    }
  };

  useEffect(() => {
    if (shareData.fileData?.type === ProcessMethodE.Gemini) {
      const { arrayList, keyMap } = tool.convertObjectListToArrayList(
        shareData.fileData?.data
      );
      const fieldsInDB = Object.keys(shareData.fields);
      const fieldsInDBMappings = fieldsInDB.reduce((acc, cur) => {
        const opt = shareData.fields[cur].options;
        const tempMappingKeys = Object.keys(keyMap);
        const fieldInDBMapping = tempMappingKeys.find((k) =>
          [cur, ...opt].includes(k.toLowerCase())
        );
        if (fieldInDBMapping) {
          acc[cur] = keyMap[fieldInDBMapping];
        }
        return acc;
      }, {});
      setRowMapping(fieldsInDBMappings);
      setCustomData(arrayList);
    } else if (shareData.fileData?.type === ProcessMethodE.InvalidJSON) {
      setInvalidVertexRes(shareData.fileData?.data);
      setShowVertexRes(true);
    }
  }, [shareData.fileData]);

  useEffect(() => {
    if (parsedVertexJson && parsedVertexJson.length) {
      const { arrayList, keyMap } =
        tool.convertObjectListToArrayList(parsedVertexJson);
      const fieldsInDB = Object.keys(shareData.fields);
      const fieldsInDBMappings = fieldsInDB.reduce((acc, cur) => {
        const opt = shareData.fields[cur].options;
        const tempMappingKeys = Object.keys(keyMap);
        const fieldInDBMapping = tempMappingKeys.find((k) =>
          [cur, ...opt].includes(k.toLowerCase())
        );
        if (fieldInDBMapping) {
          acc[cur] = keyMap[fieldInDBMapping];
        }
        return acc;
      }, {});
      setRowMapping(fieldsInDBMappings);
      setCustomData(arrayList);
    }
  }, [parsedVertexJson]);

  /**
   * Get custom data by selected range or processed data with mapping
   */
  useEffect(() => {
    let data: string[][];
    if (processForm.method === ProcessMethodE.Mapping) {
      data = selectedDataRange?.data ?? [];
    } else if (processForm.method === ProcessMethodE.Processor) {
      if (
        processedData &&
        processedData.version &&
        processedData.data &&
        ['extractTable', 'documentAI', 'SpreadSheet'].includes(
          processedData.version
        )
      ) {
        data = [...processedData.data];
        if (processedData.sheet) {
          // Set to form value
          handleProcessFormChange('selectedSheet', processedData.sheet);
        }
      } else if (shareData.isExcel) {
        // For some excel/csv file, we need use processor to get data
        data = selectedDataRange?.data ?? [];
      } else {
        return;
      }
    } else if (processForm.method === ProcessMethodE.Compgrid) {
      data = [...processedData.data];
    } else if (processForm.method === ProcessMethodE.Vertex) {
      data = [...processedData.data];
    } else {
      return;
    }
    setCustomData(data);
  }, [selectedDataRange, processedData, processForm.method]);

  /**
   * Get processed data by mapping and custom data (closely like the data in the table)
   */
  useEffect(() => {
    if (
      Object.keys(rowMapping || {}).length &&
      customData.length &&
      shareData.fields
    ) {
      const mapingEntries = Object.entries(rowMapping);
      const rowList = customData.map((item) => {
        const row = mapingEntries.reduce((acc, [k, v]) => {
          // Format value
          let formattedValue: string;
          const targetField = shareData.fields?.[k] || {};
          if (typeof v === 'string') {
            formattedValue = v;
          } else if (targetField.formatter) {
            formattedValue = targetField.formatter(item[v]);
          } else {
            formattedValue = item[v];
          }
          acc[k] = formattedValue;
          return acc;
        }, {});
        return row;
      });
      setProcessedRowList(rowList);
    } else if (!customData.length) {
      setProcessedRowList([]);
    }
    // }
  }, [customData, rowMapping, shareData.fields, processForm.method]);

  /**
   * Check if missing required fields
   */
  useEffect(() => {
    const tips = missingRequiredFields.length
      ? `Missing required fields: ${missingRequiredFields.join(', ')}`
      : undefined;

    setErrors((error) => {
      return {
        ...error,
        missingField: tips,
      };
    });
  }, [missingRequiredFields]);

  // Load data by mapping and customData
  useEffect(() => {
    if (processedRowList.length && shareData?.fields) {
      const mappingKeys = Object.keys(rowMapping);
      const amtKeys = Object.entries(shareData.fields)
        .map(([k, v]) => {
          return v.type === 'currency' && mappingKeys.includes(k) ? k : null;
        })
        .filter(Boolean);

      const totalRowItem = {};
      amtKeys.forEach((k) => {
        const total = processedRowList.reduce((acc, cur) => {
          if (!cur[k!] || cur[k!]?.includes('❌')) return acc;
          const calcVal = currency(acc).add(cur[k!]).format();
          return calcVal;
        }, 0);
        totalRowItem[k!] = total;
      });

      // Find the first column and set it val to 'Total'
      const firstCol = Object.keys(rowMapping).find((k) => {
        return rowMapping[k] === 0;
      });
      totalRowItem[firstCol!] = 'Total';
      const rowDataDef = [...processedRowList, totalRowItem];

      setRowDataDef(rowDataDef);
    } else {
      setRowDataDef([]);
    }
  }, [processedRowList, shareData?.fields, rowMapping]);

  useEffect(() => {
    if (!rowDataDef.length || !Object.entries(rowMapping).length) return;
    const temp = cloneDeep(rowDataDef);
    const totalRow = temp.pop();
    setProcessFormatData((prev: any) => {
      return {
        ...prev,
        data: temp,
        cmsTotal: normalizeCurrency(totalRow?.commission_amount || ''),
        mappingOptions: rowMapping,
      };
    });
  }, [rowDataDef, rowMapping]);

  const dataTypeDefinitions = useMemo(() => {
    return {
      dateString: {
        baseDataType: 'dateString',
        extendsDataType: 'dateString',
        valueParser: (params) =>
          params.newValue != null &&
          params.newValue.match('\\d{2}/\\d{2}/\\d{4}')
            ? params.newValue
            : null,
        valueFormatter: (params) => (params.value == null ? '' : params.value),
        dataTypeMatcher: (value) =>
          typeof value === 'string' && !!value.match('\\d{2}/\\d{2}/\\d{4}'),
        dateParser: (value) => {
          if (value == null || value === '') {
            return undefined;
          }
          const dateParts = value.split('/');
          return dateParts.length === 3
            ? dayjs.utc(new Date(value)).toDate()
            : undefined;
        },
        dateFormatter: (value) => {
          if (value == null) {
            return undefined;
          }
          const date = String(value.getDate());
          const month = String(value.getMonth() + 1);
          return `${month.length === 1 ? '0' + month : month}/${date.length === 1 ? '0' + date : date}/${value.getFullYear()}`;
        },
      },
    };
  }, []);

  // Load table column by mapping
  useEffect(() => {
    let tempMapping = { ...rowMapping };
    if (shareData.fileData?.type === 'GPT') {
      tempMapping = shareData.fileData?.data[0];
    }
    const _keys = Object.keys(tempMapping);
    if (_keys.length) {
      const _columnDefs = _keys.map((k) => {
        const targetField = shareData.fields?.[k] || {};
        const baseConf: ColDef = {
          headerName: targetField.label || k,
          field: k,
          minWidth: 160,
          editable: true,
          filter: true,
          resizable: true,
          // checkboxSelection: index == 0, // show checkbox in first column
        };
        if (
          (targetField.type && targetField.type === 'date') ||
          k.includes('_date')
        ) {
          return {
            ...baseConf,
            cellEditor: 'agDateStringCellEditor',
            cellEditorParams: {
              min: '1900-01-01',
              max: '2100-01-01',
            },
          } as ColDef;
        } else {
          return baseConf;
        }
      });
      const actionColumn: ColDef = {
        headerName: '',
        field: 'action',
        width: 24,
        pinned: 'left',
        cellStyle: {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        },
        // headerComponent: () => <DeleteOutlineOutlined sx={{ opacity: 0.5 }} />,
        cellRenderer: (params) => {
          if (!params?.node?.lastChild) {
            return (
              <IconButton
                sx={{
                  ':hover': {
                    opacity: 1,
                    color: 'error.main',
                  },
                  opacity: 0.55,
                }}
                onClick={() => {
                  const rowIndex = params.node.rowIndex;
                  setCustomData((pre) => {
                    const newData = [...pre];
                    newData.splice(rowIndex, 1);
                    return newData;
                  });
                  addActionCount(DocumentProcessActionTypes.DELETE_DATA);
                }}
              >
                <Clear />
              </IconButton>
            );
          } else {
            return (
              <Tooltip
                title="Totals calculated and provided for review only. This row will not be imported."
                arrow
              >
                <IconButton sx={{ opacity: 0.55 }}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            );
          }
        },
      };
      setColumnDefs([actionColumn, ..._columnDefs]);
    }
  }, [rowMapping, shareData.fields, shareData.fileData]);

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    const { rowIndex, colDef, newValue } = params;
    const { field } = colDef;
    const newData = [...customData];

    // Format value
    let formattedValue: string | number;
    const targetField = shareData.fields?.[field!] || {};
    if (typeof rowMapping[field!] === 'string') {
      formattedValue = rowMapping[field!];
    } else if (targetField.formatter) {
      formattedValue = targetField.formatter(newValue);
    } else {
      formattedValue = newValue;
    }

    // Fill out all rows with same value if it not empty
    newData[rowIndex!][rowMapping[field!]] = formattedValue;
    newData.forEach((row, index) => {
      const cur = row[rowMapping[field!]];
      if (!cur && index !== rowIndex!) {
        row[rowMapping[field!]] = formattedValue;
      }
    });

    addActionCount(DocumentProcessActionTypes.EDIT_DATA);
    setCustomData(newData);
    setErrors((error) => {
      return {
        ...error,
        dataRows: undefined,
      };
    });
  };

  const onFilterChanged = (params: FilterChangedEvent) => {
    // Get the filtered data
    const renderedRows = params.api.getRenderedNodes();
    const filteredRows = renderedRows.map((rowNode) => rowNode.data);
    setProcessFormatData((prev: any) => {
      return {
        ...prev,
        data: filteredRows,
      };
    });
    addActionCount(DocumentProcessActionTypes.FILTER_DATA);
  };

  const gridOptions = useMemo(() => {
    return {
      defaultColDef: {
        sortable: true,
        flex: 1,
      },
      animateRows: true,
      // rowSelection: 'single',
      // unSortIcon: true,
      suppressMenuHide: true,
      alwaysShowHorizontalScroll: true,
    };
  }, [rowMapping]);

  const addField = () => {
    const currentFields = columnDefs.map((item) => item.field).filter(Boolean);
    const _canAddFields = shareData.allFieldKeys
      .map((item) => {
        return !currentFields.includes(item.key) ? item : null;
      })
      .filter(Boolean) as IFieldsProps[];
    setCanAddFields(_canAddFields);
    setOpen(true);
    addActionCount(DocumentProcessActionTypes.ADD_DATA);
  };

  const removeField = () => {
    const currentFields = columnDefs.map((item) => item.field).filter(Boolean);
    const formattedFields = currentFields
      .filter((s) => s && !s.includes('action'))
      .map((k) => {
        const target = shareData.allFieldKeys.find((item) => item.key === k);
        if (target) {
          return target;
        } else {
          return {
            key: k,
            label: k,
            type: 'text',
          };
        }
      });
    setCanRemoveFields(formattedFields as any);
    setShowRemove(true);
    addActionCount(DocumentProcessActionTypes.DELETE_DATA);
  };

  const addRow = () => {
    const newRow = Object.entries(rowMapping).reduce((acc, [k, v]) => {
      acc[k] = '';
      return acc;
    }, {});
    newRow['action'] = '';
    setCustomData((pre: any) => {
      return [newRow, ...pre];
    });
    addActionCount(DocumentProcessActionTypes.ADD_DATA);
  };

  const onAddFields = (data: IFieldsProps | undefined) => {
    if (!data) return;
    const baseConf: ColDef = {
      headerName: data.label,
      field: data.key,
      minWidth: 160,
      editable: true,
      filter: true,
      resizable: true,
      // checkboxSelection: index == 0, // show checkbox in first column
    };
    let option = baseConf;
    if (data.key.includes('_date') || data.type === 'date') {
      option = {
        ...baseConf,
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '01/01/2020',
          max: '01/01/2050',
        },
      } as ColDef;
    }
    setRowMapping((prev) => {
      return {
        ...prev,
        [data.key]: columnDefs.length,
      };
    });
    setColumnDefs((pre) => {
      return cloneDeep([...pre, option]);
    });
    setOpen(false);
  };

  const onRemoveFields = (data: IFieldsProps[]) => {
    if (!data.length) return;
    const delList = data.map((item) => item.key);
    const newRows = rowDataDef.map((row) => {
      delList.forEach((k) => {
        Reflect.deleteProperty(row, k);
      });
      return row;
    });

    const { keys, values } = tool.convertMapToArray(newRows);

    const { arrayList, keyMap } = tool.convertObjectListToArrayList(newRows);
    setRowMapping(keyMap);
    setCustomData(arrayList);

    setProcessedData((prev) => {
      return {
        ...prev,
        data: values,
        fields: keys,
      };
    });

    setShowRemove(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onRemoveClose = () => {
    setShowRemove(false);
  };

  const getRowStyle = (params) => {
    if (params.node.lastChild) {
      return { background: '#F0F0F0' };
    }
  };

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {rowDataDef.length > 1 && (
            <Chip label={`Rows: ${rowDataDef.length - 1}`} />
          )}
          <Button variant="outlined" onClick={addField}>
            Add field
          </Button>
          <Button variant="outlined" onClick={addRow}>
            Add row
          </Button>
          <Button variant="outlined" onClick={removeField}>
            Remove field
          </Button>
          <Tooltip title="Double-click cell to edit" placement="left" arrow>
            <InfoOutlined sx={{ color: '#aaa', mr: 1 }} />
          </Tooltip>
          <Tooltip title="Export as csv" placement="bottom" arrow>
            <FileDownload
              sx={{ color: '#aaa', pt: 0.25 }}
              onClick={() => {
                exportCsv(
                  Object.keys(rowDataDef[0]).filter((k) => k !== 'action'),
                  rowDataDef,
                  `Fintary-Preview-Export.csv`
                );
              }}
            />
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%', flex: 1 }} className="ag-theme-material">
          <AgGridReact
            headerHeight={40}
            rowData={rowDataDef}
            columnDefs={columnDefs}
            onCellValueChanged={onCellValueChanged}
            onFilterChanged={onFilterChanged}
            // @ts-ignore
            dataTypeDefinitions={dataTypeDefinitions}
            getRowStyle={getRowStyle}
            {...gridOptions}
          />
        </Box>
      </Box>
      <RemoveFieldsDialog
        open={showRemove}
        fieldsSource={canRemoveFields}
        onClose={onRemoveClose}
        onConfirm={onRemoveFields}
      />
      <VertexResult
        open={showVertexRes}
        onClose={() => setShowVertexRes(false)}
        onConfirm={onConfirmVertexRes}
        json={invalidVertexRes}
        extraction={shareData.fileData?.extraction}
      />
      <AddFieldsDialog
        open={open}
        fieldsSource={canAddFields}
        onClose={onClose}
        onConfirm={onAddFields}
      />
    </>
  );
};

export default CommissionPreview;

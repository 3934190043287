import {
  Add,
  DeleteOutline,
  DownloadOutlined,
  Edit,
  LaunchOutlined,
  Start,
  SyncOutlined,
} from '@mui/icons-material';
import { Alert, Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import CommonFormatter from 'common/Formatter';
import { numberOrDefault } from 'common/helpers';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, Navigate, useOutletContext } from 'react-router-dom';

import { BasicDialog, FileDialogPreview } from '@/common';
import { PDF_IMG_TYPES } from '@/common/preview/model';
import UploadOverrideFile from '@/components/documents/DocumentsView/DocumentOverrideFile';
import ExtractMethod from '@/components/documents/DocumentsView/ExtractMethod';
import UpdateProcessData from '@/components/documents/DocumentsView/UpdateProcessData';
import SplitButton from '@/components/molecules/SplitButton';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { UIStateContext } from '@/contexts/UIStateProvider';
import usePreviewParams from '@/contexts/usePreviewParams';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import Storage from '@/services/Storage';
import UILabels from '@/services/UILabels';
import { getFileData } from '@/services/helpers';
import useAccountStore from '@/store/accountStore';
import { useSetOriginFile } from '@/store/excelStore';
import { DocumentPreviewKeys, DocumentTypes, OutletContextType } from '@/types';
import ActionDialog from './ActionDialog';

const DocumentsView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const exportPoster = API.getMutation('statement_data/export', 'POST', {
    rawData: true,
  });

  const { showPreview, setShowPreview, previewId, setPreviewPath } =
    usePreviewParams();

  const {
    role: [role],
  } = useContext(UIStateContext);

  const syncStatement = API.getMutation(
    'data_processing/sync/benefit-point/statements',
    'POST'
  );
  const viewSettings = accountSettings?.pages_settings?.documents;

  const { data: companies } = API.getBasicQuery('companies');

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }
  const { openSnackbar } = useOutletContext<OutletContextType>();
  const [open, setOpen] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showExtract, setShowExtract] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [processLoading, setProcessLoading] = useState<number | ''>('');
  const [curRow, setCurRow] = useState<any>();

  const [showEdit, setShowEdit] = useState<boolean | null>(null);

  const [sync, setSync] = useState({ documentId: '', show: false, count: 0 });

  const [rowData, setRowData] = useState<any>({});
  const { setLoadingConfig } = useContext(LoadingContext);

  const setUploadedFile = useSetOriginFile();

  const documentsDelete = API.getMutation('documents', 'DELETE');
  const documentPutter = API.getMutation('documents', 'PUT');
  const { data: curRowData, isLoading: isCurRowLoading } = API.getBasicQuery(
    'extractions',
    `document_id=${curRow?.id}`,
    !!curRow?.id
  );

  const queryClient = useQueryClient();

  const { selectedAccount } = useAccountStore();
  const mode = selectedAccount?.accountMode;
  const labels = new UILabels(mode);

  // Hard coded for now, will be dynamic later
  const enableAccountId = ['W4kSrayZvmh26pGfYVrGE', 'fFF86XAy2Cu97xxra8lgA'];
  const isRiskTag =
    enableAccountId.includes(selectedAccount?.accountId || '') ||
    selectedAccount?.accountName?.toLowerCase().includes('risktag');

  const filePathFormatter = (filename, row) =>
    filename ? (
      <Tooltip title={filename} arrow enterNextDelay={1000}>
        <Button
          onClick={async () => {
            setPreviewPath(row.str_id, DocumentPreviewKeys.ORIGINAL);
            setShowPreview(true);
          }}
        >
          <span
            style={{
              maxWidth: 240,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {filename}
          </span>
        </Button>
      </Tooltip>
    ) : (
      '--'
    );

  const syncToBenefit = async (data) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Syncing...',
      });
      const ret = await syncStatement.mutateAsync(data);
      if (ret.success === false) {
        const tip = (
          <Alert severity="error">
            Sync failed: {ret.message || ret.statusText}
          </Alert>
        );
        openSnackbar(tip);
      } else {
        openSnackbar(
          <Alert severity="success">
            Synced statmentID: {ret.statementId}
            <br />
            {ret.create.length > 0
              ? `${ret.create.length} statement ${ret.create.length > 1 ? 'entries' : 'entry'} created`
              : ''}
            <br />
            {ret.invalid.length > 0
              ? `${ret.invalid.length} statement ${ret.invalid.length > 1 ? 'reports' : 'report'} invalid`
              : ''}
          </Alert>
        );
      }
    } catch (error: any) {
      const tip = <Alert severity="error">{error.message || error}</Alert>;
      openSnackbar(tip);
    } finally {
      setLoadingConfig({
        loading: false,
        message: '',
      });
    }
  };

  const exportData = async (data) => {
    const params = {
      document_id: data.str_id,
      company_str_id: data.company_str_id,
    };
    try {
      setLoadingConfig({
        loading: true,
        message: 'Exporting...',
      });
      const res = await exportPoster.mutateAsync(params);
      setLoadingConfig({
        loading: false,
        message: '',
      });
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${data.filename}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error: any) {
      setLoadingConfig({
        loading: false,
        message: '',
      });
      const tip = <Alert severity="error">{error.message || error}</Alert>;
      openSnackbar(tip);
    }
  };

  const deleteData = async (row) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Deleting...',
      });
      await documentsDelete.mutateAsync({ id: row.id });
      queryClient.invalidateQueries();
      setLoadingConfig({
        loading: false,
        message: '',
      });
      openSnackbar(<Alert severity="success">Deleted {row.filename}</Alert>);
    } catch (error: any) {
      setLoadingConfig({
        loading: false,
        message: '',
      });
      const tip = <Alert severity="error">{error.message || error}</Alert>;
      openSnackbar(tip);
    }
  };

  /**
   * Extraxt data from file, if file is image or pdf, show an select option to choose which method to extract data (1. Google Document AI, 2. ExtractTable)
   * @param {object} row documents table row
   */
  useEffect(() => {
    if (curRow && isCurRowLoading) {
      setProcessLoading(curRow.id);
    }
    if (isCurRowLoading || !curRow) {
      return;
    }
    const extractData = async (row) => {
      const filepath = curRow?.override_file_path
        ? curRow?.override_file_path
        : curRow?.file_path;
      const filename = curRow?.override_file_path
        ? curRow?.override_file_path
        : curRow?.filename;
      try {
        const url = await Storage.getFileUrl(filepath);
        const file = await getFileData(url, filename);
        setRowData(() => {
          return {
            ...curRow,
            url,
            extractions: curRowData,
          };
        });
        setUploadedFile(file);
        setProcessLoading('');
        if (PDF_IMG_TYPES.includes(file.type)) {
          setShowExtract(true);
        } else {
          setOpen(true);
        }
        setCurRow(undefined);
      } catch (error: any) {
        setCurRow(undefined);
        const tip = <Alert severity="error">{error?.message || error}</Alert>;
        openSnackbar(tip);
      }
    };
    extractData(curRowData);
  }, [curRowData, isCurRowLoading, curRow]);

  const markProcessed = async (row) => {
    const { company_str_id, file_type, id } = row;
    setProcessLoading(row.id);
    const res = await documentPutter.mutateAsync({
      company_str_id,
      file_type,
      id,
      status: 'processed',
    });
    setProcessLoading('');
    if (res.error) {
      const tip = <Alert severity="error">{res.error}</Alert>;
      openSnackbar(tip);
    } else {
      queryClient.invalidateQueries();
    }
  };

  /**
   * Action, currently only based on file data to process data.  later will be based on mappings to git raw data.
   * @param {string} s file path
   * @param {object} row
   */
  const fileActionFormatter = (s, row) =>
    row.override_file_path || row.file_path ? (
      <>
        <Box className="flex">
          <SplitButton
            startIcon={<Start />}
            loading={processLoading === row.id}
            useLoadingBtn
            disabled={row.type === DocumentTypes.COMPGRID}
            options={
              row.status === 'new'
                ? [
                    {
                      id: 'process',
                      label: 'Process',
                      onClick: () => {
                        setCurRow(row);
                      },
                    },
                    {
                      id: 'mark',
                      label: 'Mark as processed',
                      onClick: () => markProcessed(row),
                      disabled: row.status !== 'new',
                    },
                  ]
                : [
                    {
                      id: 'process',
                      label: 'Process',
                      onClick: () => {
                        setCurRow(row);
                      },
                    },
                  ]
            }
            variant={row.status === 'new' ? 'contained' : 'outlined'}
          />
          {row.status === 'new' && (
            <IconButton
              className="ml-1"
              onClick={() => {
                setRowData(row);
                setShowConfirm(true);
              }}
            >
              <DeleteOutline />
            </IconButton>
          )}
          <IconButton
            className="ml-1"
            onClick={() => {
              setRowData(row);
              setShowEdit(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      </>
    ) : (
      '--'
    );

  const fileActionFormatterBasic = (s, row) =>
    row.override_file_path || row.file_path ? (
      <>
        <Box className="flex">
          {row.status === 'new' && (
            <IconButton
              className="ml-1"
              onClick={() => {
                setRowData(row);
                setShowConfirm(true);
              }}
            >
              <DeleteOutline />
            </IconButton>
          )}
          <IconButton
            className="ml-1"
            onClick={() => {
              setRowData(row);
              setShowEdit(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      </>
    ) : (
      '--'
    );

  const overrideFilePathFormatter = (s, row) =>
    s ? (
      <Tooltip title={s} arrow enterNextDelay={1000}>
        <Button
          onClick={async () => {
            setPreviewPath(row.str_id, DocumentPreviewKeys.OVERRIDE);
            setShowPreview(true);
          }}
        >
          <span
            style={{
              maxWidth: 240,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {s}
          </span>
        </Button>
      </Tooltip>
    ) : (
      <Box sx={{ ml: 4 }}>
        <Tooltip title="Add override file" placement="right" arrow>
          <IconButton
            onClick={() => {
              setRowData(row);
              setShowUploadModal(true);
            }}
          >
            <Add sx={{ height: 18 }} />
          </IconButton>
        </Tooltip>
      </Box>
    );

  const dataDesc = useMemo(
    () => ({
      label: labels.getLabel('documents', 'title'),
      table: 'documents',
      fields: {
        filename: {
          label: 'File',
          enabled: true,
          formatter: filePathFormatter,
        },
        override_filename: {
          label: 'Override file',
          enabled: true,
          formatter: overrideFilePathFormatter,
          access: 'admin',
        },
        type: {
          label: 'Type',
          enabled: true,
          formatter: Formatter.documentType,
        },
        companies: {
          label: 'Company',
          enabled: true,
          formatter: Formatter.getLinkChipFormatter(
            'company_name',
            'str_id',
            '/companies?id='
          ),
        },
        statement_data: {
          label: 'Records',
          disableSort: true,
          enabled: true,
          formatter: (val, row) => {
            const groupedCountInfoStrList: string[] = [];
            const groupedCommissionInfoStrList: string[] = [];
            if (val.groupedCountInfo) {
              Object.entries(val.groupedCountInfo).forEach(([key, value]) => {
                if (key !== 'NULL_KEY') {
                  groupedCountInfoStrList.push(`${key}: ${value}`);
                }
              });
            }
            if (val.groupedCommissionInfo) {
              Object.entries(val.groupedCommissionInfo).forEach(
                ([key, value]) => {
                  if (key !== 'NULL_KEY') {
                    groupedCommissionInfoStrList.push(
                      `${key}: ${Formatter.currency(value)}`
                    );
                  }
                }
              );
            }
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '120px',
                    height: 100,
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    {!!val.total_count && (
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span>{+val.total_count}</span>
                        {groupedCountInfoStrList.length > 0 && (
                          <span
                            style={{
                              color: '#444',
                              fontSize: 13,
                            }}
                          >
                            ({groupedCountInfoStrList.toString()})
                          </span>
                        )}
                      </Box>
                    )}
                    {!!val.total_commission && (
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span>{Formatter.currency(val.total_commission)}</span>
                        {groupedCommissionInfoStrList.length > 0 && (
                          <span
                            style={{
                              color: '#444',
                              fontSize: 13,
                            }}
                          >
                            ({groupedCommissionInfoStrList.toString()})
                          </span>
                        )}
                      </Box>
                    )}
                    {!val.total_commission && !val.total_count && '0'}
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {(val.total_commission > 0 || val.total_count > 0) && (
                      <>
                        <IconButton
                          component={Link}
                          to={`/${row.type === 'statement' ? 'commissions' : 'policies'}?q=${row.str_id}`}
                          target="_blank"
                          sx={{
                            opacity: 0.5,
                            '&:hover': { opacity: 1 },
                            color: '#2196f3',
                          }}
                        >
                          <LaunchOutlined />
                        </IconButton>
                        {isRiskTag && (
                          <>
                            {row?.statement_data?.total_count > 0 && (
                              <Tooltip
                                title={`${row?.statement_data?.total_count} statement entries will be synced to BenefitPoint`}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() =>
                                    setSync({
                                      documentId: row.str_id,
                                      show: true,
                                      count:
                                        row?.statement_data?.total_count || 0,
                                    })
                                  }
                                  size="small"
                                  sx={{
                                    opacity: 0.5,
                                    '&:hover': { opacity: 1 },
                                    color: '#2196f3',
                                  }}
                                >
                                  <SyncOutlined />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => exportData(row)}
                              size="small"
                              sx={{
                                opacity: 0.5,
                                '&:hover': { opacity: 1 },
                                color: '#2196f3',
                              }}
                            >
                              <DownloadOutlined />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </>
            );
          },
        },
        bank_total_amount: {
          label: 'Commission totals',
          enabled: true,
          disableSort: true,
          formatter: (v, row) => {
            const statementData = row.statement_data;
            const bankTotalAmount = numberOrDefault(
              row.bank_total_amount,
              null,
              { toFixed: 2 }
            );
            const totalCommissionAmount = numberOrDefault(
              statementData.total_commission,
              null,
              { toFixed: 2 }
            );
            const statementTotalAmount = numberOrDefault(
              row.imports?.[0]?.statement_total_amount,
              null,
              { toFixed: 2 }
            );

            if (row.status === 'new') {
              return `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`;
            }

            const bankAndTotalCmsIsEqual = isEqual(
              bankTotalAmount,
              totalCommissionAmount
            );
            const statementAndCmsTotalIsEqual = isEqual(
              totalCommissionAmount,
              statementTotalAmount
            );

            let matchesNode = <></>;

            if (
              bankAndTotalCmsIsEqual &&
              statementAndCmsTotalIsEqual &&
              bankTotalAmount !== null
            ) {
              const tip =
                'Bank total, statement total, and commission records all match';
              matchesNode = (
                <Tooltip title={tip} placement="right" arrow>
                  <span>{`✅ ${Formatter.currency(bankTotalAmount)}`}</span>
                </Tooltip>
              );
            } else if (bankAndTotalCmsIsEqual && totalCommissionAmount) {
              const tip = (
                <span>
                  Bank total and commissions match
                  <br />
                  (Statement total not available)
                </span>
              );
              matchesNode = (
                <Tooltip title={tip} placement="right" arrow>
                  <span>{`✅ ${Formatter.currency(bankTotalAmount)}`}</span>
                </Tooltip>
              );
            } else if (statementAndCmsTotalIsEqual && totalCommissionAmount) {
              const tip =
                'Statement total and commission records match (Bank total not available)';
              matchesNode = (
                <Tooltip title={tip} placement="right" arrow>
                  <span>
                    {`✅ ${Formatter.currency(statementTotalAmount)}`}
                  </span>
                </Tooltip>
              );
            } else if (
              !bankTotalAmount &&
              !statementTotalAmount &&
              !totalCommissionAmount
            ) {
              matchesNode = <span>0</span>;
            } else if (
              totalCommissionAmount &&
              !statementTotalAmount &&
              !bankTotalAmount
            ) {
              matchesNode = (
                <Tooltip
                  title={
                    <span>
                      Validation not available.
                      <br />
                      Bank total and/or statement total required.
                    </span>
                  }
                  placement="right"
                  arrow
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ fontSize: 12 }}>ℹ️</Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span>
                        {`Commissions: ${Formatter.currency(totalCommissionAmount)}`}
                      </span>
                    </Box>
                  </Box>
                </Tooltip>
              );
            } else {
              matchesNode = (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ fontSize: 12 }}>❌</Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span>{`Commissions: ${Formatter.currency(totalCommissionAmount)}`}</span>
                    <span>{`Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`}</span>
                    <span>{`Statement total: ${Formatter.currency(statementTotalAmount) || 'n/a'}`}</span>
                  </Box>
                </Box>
              );
            }
            return <Box sx={{ whiteSpace: 'nowrap' }}>{matchesNode}</Box>;
          },
        },
        created_by: {
          label: 'Imported total',
          enabled: true,
          disableSort: true,
          formatter: (v, row) => {
            const list = row.imports;
            if (list && list.length) {
              const target = list[0];
              return Formatter.currency(target?.summed_total_amount);
            }
          },
          access: 'admin',
        },
        status: {
          label: 'Status',
          enabled: true,
          formatter: (val) =>
            Formatter.statusChip(val, {
              mapping: { new: 'yellow', processed: 'green' },
            }),
        },
        method: {
          label: 'Method',
          enabled: true,
          access: 'admin',
          formatter: (val: string, row) =>
            val ? (
              <Chip
                label={val}
                component={Link}
                to={`/documents/profiles?id=${row.profile_str_id}`}
              />
            ) : null,
        },
        notes: {
          label: 'Notes',
          enabled: true,
        },
        imported_at: {
          label: 'Imported at',
          enabled: true,
          access: 'admin',
          formatter: (s: string, row) => {
            if (!s) {
              return '';
            }
            const uploadToImportTime = dayjs(s).diff(
              dayjs(row.created_at),
              'milliseconds'
            );
            const uploadedInRes = `${CommonFormatter.duration(uploadToImportTime, { truncate: 'seconds' })}`;
            return `${Formatter.date(s, {
              format: 'MM/DD/YYYY hh:mmA',
            })} (${uploadedInRes})`;
          },
        },
        created_at: {
          label: 'Uploaded at',
          enabled: true,
          formatter: (s: string) => {
            return Formatter.date(s, {
              format: 'MM/DD/YYYY hh:mmA',
            });
          },
          readOnly: true,
        },
        id: {
          label: 'Actions',
          enabled: true,
          disableSort: true,
          formatter:
            role === 'admin' ? fileActionFormatter : fileActionFormatterBasic,
          // access: ['admin', 'demo'],
        },
      },
      queryChips: {
        all: {
          id: 'all',
          label: 'All',
          query: {},
        },
        new: {
          id: 'new',
          label: 'New',
          query: {
            status: 'new',
          },
        },
        processed: {
          id: 'processed',
          label: 'Processed',
          query: {
            status: 'processed',
          },
        },
      },
    }),
    [labels]
  );

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  const onConfirmMethod = (method) => {
    setRowData((prev) => ({ ...prev, method }));
    setShowExtract(false);
    setOpen(true);
  };

  const onSetShowEdit = (isShow, action = 'cancel') => {
    setShowEdit(isShow);
    if (action === 'save') {
      queryClient.invalidateQueries();
    }
  };

  const extraActions = [
    {
      type: 'dateRange',
      label: 'Uploaded at',
      value: {
        startDate: null,
        endDate: null,
      },
    },
  ];

  return (
    <>
      <EnhancedDataView
        dataSpec={dataDesc}
        hideAdd
        hideSelectedCount
        enableMultiSelect={false}
        enableEdit={false}
        extraActions={extraActions}
      />
      {open && (
        <UpdateProcessData
          open={open}
          rowData={rowData}
          setRowData={setRowData}
          openSnackbar={openSnackbar}
          handleClose={(arg) => {
            queryClient.invalidateQueries();
            setOpen(arg);
          }}
        />
      )}
      {showUploadModal && (
        <UploadOverrideFile
          open={showUploadModal}
          setOpen={setShowUploadModal}
          uploadedRow={rowData}
          openSnackbar={openSnackbar}
        />
      )}
      {showExtract && (
        <ExtractMethod
          showExtract={showExtract}
          onClose={() => setShowExtract(false)}
          onConfirm={onConfirmMethod}
          uploadedRow={rowData}
        />
      )}
      {showPreview && (
        <FileDialogPreview
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          fileId={previewId}
        />
      )}
      {showConfirm && (
        <BasicDialog
          open={showConfirm}
          title="Delete document"
          bodyComponent={
            <Alert severity="warning">
              Are you sure you want to delete {rowData.filename}?
            </Alert>
          }
          onClose={(isOk) => {
            if (isOk) {
              deleteData(rowData);
            }
            setShowConfirm(false);
          }}
        />
      )}

      {showEdit && (
        <ActionDialog
          open={showEdit}
          setOpen={onSetShowEdit}
          rowData={rowData}
          companies={companies}
        />
      )}
      <BasicDialog
        open={sync.show}
        title="Sync data"
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to sync {sync.count} statement entries to
            BenefitPoint?
          </Alert>
        }
        onClose={(isOk) => {
          if (isOk) {
            syncToBenefit({ documentId: sync.documentId });
          }

          setSync({ ...sync, show: false });
        }}
        positiveLabel="Sync"
      />
    </>
  );
};

export default DocumentsView;

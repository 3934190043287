import {
  QueryClient,
  useMutation,
  useQuery,
  useQueries,
} from '@tanstack/react-query';

import { impUser, addUid } from '@/services/helpers';
import { auth } from '@/firebase';
import packageJson from '../../package.json';
import { GlobalStateCodes } from '@/types';
import useFeVersionStore from '@/store/feVersioningStore';

type EndpointType =
  | 'accounts'
  | 'accounts/get_onboarding_accounts'
  | 'accounts/settings/views-and-fields'
  | 'accounts/settings'
  | 'accounts/settings/companies'
  | 'admin/accounts'
  | 'admin/check'
  | 'admin/users'
  | 'admin/users/verify-user-account'
  | 'admin/users/reset-user-password'
  | 'auth_handler'
  | 'comments'
  | 'comp-grids'
  | 'comp-grids/criteria'
  | 'comp-grids/levels'
  | 'comp-grids/products'
  | 'comp-grids/rates'
  | 'comp-grids/view'
  | `companies?${any}`
  | 'companies'
  | `companies/${any}`
  | 'contacts'
  | 'contacts/contacts_without_user'
  | 'contacts/groups'
  | 'contacts/options'
  | `contacts/options?${any}`
  | 'data_processing'
  | 'data_processing/commissions/agents'
  | 'data_processing/sync/worker'
  | 'data_processing/sync/synced-fields'
  | 'data_processing/sync/nowcerts/policy'
  | 'data_processing/sync/benefit-point/statements'
  | 'document_profiles'
  | 'documents'
  | 'documents/adobeExtract'
  | 'documents/docAI'
  | 'documents/extractData'
  | 'dynamic_selects'
  | 'export/reconciliation_data'
  | 'extractions'
  | 'fields?model=reports'
  | 'fields?model=statements'
  | 'fields'
  | 'history'
  | 'insights'
  | 'insights/preview'
  | 'login'
  | 'mappings'
  | 'notifications'
  | 'processors'
  | 'prompts'
  | 'reconciliation_data'
  | 'report_data'
  | 'report_data/agents'
  | 'report_data/bulk_update'
  | 'report_data/transaction_type'
  | 'roles'
  | 'saved_reports'
  | 'saved_reports/groups/bulk_share'
  | 'saved_reports/groups/gen_commissions_report'
  | 'saved_reports/groups/details'
  | 'saved_reports/views'
  | 'schedules/agents'
  | 'schedules/agents/incentive_tiers'
  | 'schedules/agents/sets'
  | 'schedules/carriers'
  | 'schedules/comp_grids'
  | 'schedules/comp-profiles'
  | 'schedules/comp-profile-sets'
  | 'statement_data'
  | 'statement_data/carrier_name'
  | 'statement_data/compensation_type'
  | 'statement_data/options'
  | 'statement_data/transaction_type'
  | 'statement_data/export'
  | 'stripe/payment_links'
  | 'users'
  | 'users/get_account_users'
  | 'users/get_fintary_admins'
  | 'users/invite_new_user'
  | `companies/${any}`
  | `extractions/${any}`
  | `processors/${any}`
  | 'saved_reports/groups/bulk_share'
  | 'comments'
  | 'gpt'
  | 'gpt/doc'
  | 'prompts'
  | 'comp-grids/rates'
  | 'admin/users/verify_user_account'
  | 'data_imports'
  | `data_imports/${any}`
  | 'date-ranges'
  | 'comp-grids/rates/copy-rates'
  | 'comp-grids/rates/bulk-edit'
  | 'saved_reports/reports';

type ApiQueryType = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

class API {
  public queryClient: QueryClient;
  constructor() {
    this.queryClient = new QueryClient();
  }

  static getEmbeddingQuery = (str) => ({
    queryKey: [str],
    queryFn: async () => {
      const res = await fetch(`${process.env.REACT_APP_AI}/encode`, {
        method: 'POST',
        headers: await this.getHeaders(),
        body: JSON.stringify({
          query: str,
        }),
      });
      return res.json();
    },
    enabled: !!auth?.currentUser,
  });

  static getEmbeddings = (strs) => {
    return useQueries({
      queries: strs.map((str) => this.getEmbeddingQuery(str)),
    });
  };

  static getHeaders = async () => {
    const idToken = await auth.currentUser?.getIdToken();
    const ssoToken = localStorage.getItem('sso-token');
    const selectedAccountId = JSON.parse(
      localStorage.getItem('selectedAccount') ?? '{}'
    ).accountId;
    const userRole = JSON.parse(localStorage.getItem('userRole') ?? '{}');
    return impUser({
      authentication: `Bearer ${idToken ? idToken : ssoToken}`,
      'content-type': 'application/json',
      accountid: selectedAccountId,
      userrole: userRole,
      feversion: packageJson.version,
    });
  };

  static getUser = () => {
    return useQuery({
      queryKey: ['users'],
      queryFn: async () => {
        const res = await fetch(`${process.env.REACT_APP_API}/api/users`, {
          method: 'GET',
          headers: await this.getHeaders(),
        });
        return res.json();
      },
      enabled: !!auth?.currentUser,
    });
  };

  static getBasicQuery = (
    endpoint: EndpointType,
    additionalQueryParams = '',
    enabled = true
  ) => {
    const selectedAccountId = JSON.parse(
      localStorage.getItem('selectedAccount') ?? '{}'
    ).accountId;
    const { setFeVersion } = useFeVersionStore();
    const abortController = new AbortController();
    const abortReason = 'Request aborted';
    const query = useQuery({
      queryKey: [selectedAccountId, endpoint, additionalQueryParams],
      queryFn: async () => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API}/api/${endpoint}${
              additionalQueryParams
                ? `${endpoint.includes('?') ? '&' : '?'}${additionalQueryParams}`
                : ''
            }`,
            {
              method: 'GET',
              signal: abortController.signal,
              headers: await this.getHeaders(),
            }
          );

          const data = await res.json();

          // If FE version is not updated just show a message to the user
          const outOfDate = res.headers.get('out_of_date');
          if (outOfDate && outOfDate === GlobalStateCodes.FE_OUT_OF_DATE) {
            setFeVersion({
              stateCode: GlobalStateCodes.FE_OUT_OF_DATE,
              message:
                'A new version of Fintary is available. Refresh this page to update.',
            });
          }

          // If FE version is incompatible with BE the response is empty
          if (
            res.status === 400 &&
            data?.error === GlobalStateCodes.FE_INCOMPATIBLE
          ) {
            setFeVersion({
              stateCode: GlobalStateCodes.FE_INCOMPATIBLE,
              message:
                'A new version of Fintary is required. Refresh this page to update.',
            });

            return '';
          }

          return data;
        } catch (err) {
          if (err === abortReason) {
            console.log(err);
            return;
          }
          throw err;
        }
      },
      enabled: enabled && !!auth?.currentUser,
    });
    return { ...query, abort: () => abortController.abort(abortReason) };
  };

  // use fetch to get data from api
  static get = async (endpoint: EndpointType, additionalQueryParams = '') => {
    const res = fetch(
      `${process.env.REACT_APP_API}/api/${endpoint}${
        additionalQueryParams ? `?${additionalQueryParams}` : ''
      }`,
      {
        method: 'GET',
        headers: await this.getHeaders(),
      }
    );
    return (await res).json();
  };

  static getBasicQueryAll(
    endpoints: EndpointType[] = [],
    additionalQueryParams = '',
    enabled = true
  ) {
    // Ensure that endpoints is an array, if it's a string, convert it to array.
    if (typeof endpoints === 'string') {
      endpoints = [endpoints];
    }
    const queries = endpoints.map((endpoint) => ({
      queryKey: [endpoint, additionalQueryParams],
      queryFn: async () => {
        const res = await fetch(
          `${process.env.REACT_APP_API}/api/${endpoint}${
            additionalQueryParams ? `?${additionalQueryParams}` : ''
          }`,
          {
            method: 'GET',
            headers: await this.getHeaders(),
          }
        );
        return res.json();
      },
      enabled: enabled && !!auth?.currentUser,
    }));

    return useQueries({ queries });
  }

  static getMutation = (
    endpoint: EndpointType,
    method: ApiQueryType,
    options?: {
      gcTime?: number;
      rawData?: boolean;
    }
  ) => {
    if (!['POST', 'PATCH', 'DELETE', 'PUT'].includes(method)) {
      throw new Error(`Unsupported method: ${method}`);
    }
    const { feVersion } = useFeVersionStore();
    const abortController = new AbortController();
    const abortReason = 'Request aborted';
    const mutation = useMutation({
      gcTime: options?.gcTime,
      mutationFn: async (body: any) => {
        if (
          feVersion?.stateCode === GlobalStateCodes.FE_OUT_OF_DATE ||
          feVersion?.stateCode === GlobalStateCodes.FE_INCOMPATIBLE
        ) {
          console.warn(
            'FE version is not up to date. Please refresh the page.'
          );
          return {};
        }
        if (auth) {
          const baseHeader = await this.getHeaders();

          try {
            const res = await fetch(
              `${process.env.REACT_APP_API}/api/${endpoint}`,
              {
                signal: abortController.signal,
                method,
                headers: baseHeader,
                body: JSON.stringify(addUid(body)),
              }
            );

            return !options?.rawData ? res.json() : res;
          } catch (err) {
            if (err === abortReason) {
              console.log(err);
              return;
            }
            throw err;
          }
        }
        console.warn('not authed');
        return {};
      },
    });
    return { ...mutation, abort: () => abortController.abort(abortReason) };
  };
}

export default API;

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { sha256 } from 'crypto-hash';
import { nanoid } from 'nanoid';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useOriginalFile, useOverrideFile } from 'store/excelStore';
import { useBeforeUnload } from 'react-use';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';

import API from '@/services/API';
import Spreadsheet from '@/services/Spreadsheet';
import Storage from '@/services/Storage';
import { readFile } from '@/services/helpers';
import { auth } from '@/firebase';
import FilePreview from '@/common/preview';
import { SpresdsheetProps, XLS_CSV_TYPES } from '@/common/preview/model';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';

interface SelectItem {
  label: string;
  value: string;
  data: File & { path?: string };
  docType: string;
}

interface PreviewUploadsProps {
  formModel: any;
}

const PreviewUploads = forwardRef(({ formModel }: PreviewUploadsProps, ref) => {
  const [currentPreviewFile, setCurrentPreviewFile] = useState<File>();
  const [selectOptions, setSelectOptions] = useState<SelectItem[]>([]);
  const [spreadsheet, setSpreadsheet] = useState<SpresdsheetProps>();
  const [uploading, setUploading] = useState(false);

  const originalFile = useOriginalFile();
  const overrideFile = useOverrideFile();

  const documentsPoster = API.getMutation('documents', 'POST');
  const documentProfilePoster = API.getMutation('document_profiles', 'POST');

  useBeforeUnload(
    uploading,
    'You have unsaved files, are you sure you want to leave?'
  );
  useBeforeUnloadPage(
    uploading,
    'You have unsaved files, are you sure you want to leave?'
  );

  const loadUploadBody = async () => {
    // loop selectOption
    const promises = selectOptions.map(async (option) => {
      const file = option.data;
      const filename = `${nanoid()}-${file.name}`;
      const userId = auth.currentUser?.uid;
      const filePath = `uploads/${userId}/${filename}`;
      const fileContent = await readFile(file);
      const fileHash = await sha256(fileContent);

      const bankTotalKey = file?.path || '';
      const bankTotalAmount = formModel.bank_total_amounts?.[bankTotalKey];
      const body = {
        filename: file.name,
        file_path: filePath,
        type: formModel.document_type,
        method: '',
        processor: '',
        file_hash: fileHash,
        fileContent,
        status: 'new',
        company_str_id: formModel.company_str_id,
        tag: formModel.tag,
        docType: option.docType,
        bank_total_amount: bankTotalAmount
          ? normalizeCurrency(bankTotalAmount)
          : null,
      };
      return body;
    });
    const bodys = await Promise.all(promises);
    return bodys;
  };

  const postUploadsInfo = async () => {
    setUploading(true);
    const bodys = await loadUploadBody();
    // Upload file to fireStore
    const promises = bodys.map(async (body) => {
      const params = [{ path: body.file_path, contents: body.fileContent }];
      await Storage.uploadFiles(params);
    });
    await Promise.all(promises);

    // Upload data to documents table
    const params: any = [];
    for (const body of bodys) {
      Reflect.deleteProperty(body, 'fileContent');
      if (body.docType === 'original') {
        params.push({
          ...body,
          override_file_path: '',
          override_file_hash: '',
        });
      }
      if (body.docType === 'override') {
        const { file_path: filePath, file_hash: fileHash } = body;
        for (const param of params) {
          param.override_file_path = filePath;
          param.override_file_hash = fileHash;
        }
      }
    }
    const overalUploadInfo: any = [];
    for (const param of params) {
      Reflect.deleteProperty(param, 'docType');
      try {
        const uploadInfo = await documentsPoster.mutateAsync(param as any);
        // Create/update document_profile record
        const docTypeParams = {
          carrier_name: uploadInfo.company_str_id,
          paying_entity: uploadInfo.company_str_id,
          owner: '',
          field_mapping: '',
          status: 'draft',
          file_link: [param.file_path],
          notes: '',
          document_str_ids: [uploadInfo.str_id],
          mappings_str_ids: uploadInfo.mapping ? [uploadInfo.mapping] : [],
        };
        const docProfile =
          await documentProfilePoster.mutateAsync(docTypeParams);

        overalUploadInfo.push({
          document: uploadInfo,
          profile: docProfile,
        });
      } catch (error: any) {
        console.log(error.message || error);
      }
    }
    return overalUploadInfo;
  };

  const doChange = useCallback(async (file) => {
    if (!file) {
      return;
    }
    setCurrentPreviewFile(file);
  }, []);

  const onChange = async (e) => {
    const val = e.target.value;
    if (!val) {
      return;
    }
    const file = selectOptions.find((item) => item.value === val)!.data;
    doChange(file);
  };

  useEffect(() => {
    // excel, csv need to load the raw data
    const setExcelData = async () => {
      if (
        currentPreviewFile &&
        XLS_CSV_TYPES.includes(currentPreviewFile.type)
      ) {
        const res = (await Spreadsheet.loadSpreadsheet(
          currentPreviewFile
        )) as SpresdsheetProps;
        setSpreadsheet(res);
      }
    };
    setExcelData();
  }, [currentPreviewFile]);

  useEffect(() => {
    if (originalFile) {
      setSelectOptions((prev) => {
        const newOptions = originalFile.map((file) => ({
          label: file.name,
          value: file.name,
          data: file,
          docType: 'original',
        }));

        const uniqueOptions = newOptions.filter(
          (option) => !prev.some((item) => item.label === option.label)
        );

        return [...prev, ...uniqueOptions];
      });
    }
    if (overrideFile) {
      setSelectOptions((prev) => {
        const target = {
          label: `Override file: ${overrideFile[0]?.name}`,
          value: overrideFile[0]?.name,
          data: overrideFile[0],
          docType: 'override',
        };
        if (prev.some((item) => item.label === target.label)) {
          return prev;
        }
        return [...prev, target];
      });
    }
  }, [originalFile, overrideFile]);
  useEffect(() => {
    const selectOne = async () => {
      if (selectOptions.length > 0) {
        await doChange(selectOptions[0].data);
      }
    };
    selectOne();
  }, [doChange, originalFile, overrideFile, selectOptions.length]);

  useImperativeHandle(ref, () => ({
    submit: postUploadsInfo,
  }));

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 240px)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <section>
        {currentPreviewFile && (
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>File</InputLabel>
            <Select
              fullWidth
              value={currentPreviewFile.name}
              onChange={onChange}
              sx={{ marginBottom: 2 }}
              label="File"
            >
              {selectOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </section>
      <FilePreview
        previewFile={currentPreviewFile}
        previewWith={window.innerWidth * 0.8}
        // @ts-ignore
        spreadsheet={spreadsheet}
        // @ts-ignore
        setSpreadsheet={setSpreadsheet}
      />
    </Box>
  );
});
PreviewUploads.displayName = 'PreviewUploads';

export default PreviewUploads;
